import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { AxiosResponse } from "axios"
import {
  IGeneralState,
  IGetContentMediaData,
  IGetContentMediaState,
} from "../../models"
import { GetContentMedia } from "../../services/Api"

const initialGetContentMediaState: IGetContentMediaState = {
  data: [],
}

export const fetchGetContentMediaAsync = createAsyncThunk(
  "get/content/media",
  async (search: string, { fulfillWithValue, rejectWithValue }) => {
    try {
      const response: AxiosResponse<IGetContentMediaData[]> =
        await GetContentMedia(search)
      return fulfillWithValue(response?.data)
    } catch (error: any) {
      return rejectWithValue(error.response.data.errors[0].message)
    }
  },
)

export const getContentMediaSlice = createSlice({
  name: "get-content-media",
  initialState: initialGetContentMediaState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      fetchGetContentMediaAsync.fulfilled,
      (state: IGetContentMediaState, action: any) => {
        state.data = action?.payload
      },
    )
  },
})

export const contentMediaInformation = (state: IGeneralState) =>
  state?.getContentMedia?.data || []

export default getContentMediaSlice.reducer
