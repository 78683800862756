export const QUILL_MODULES = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],  // Other formatting options
      ['blockquote', 'code-block'],
      [{ 'header': 1 }, { 'header': 2 }],          // Headers
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],  // Lists
      [{ 'script': 'sub' }, { 'script': 'super' }],   // Subscript and Superscript
      [{ 'indent': '-1' }, { 'indent': '+1' }],      // Indentation
      [{ 'direction': 'rtl' }],                       // Right-to-left text direction
      [{ 'size': ['small', false, 'large', 'huge'] }], // Font size
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],  // Headers
      [{ 'color': [] }, { 'background': [] }],  // Color and background options
      ['link'], // Links, images, and videos
      ['clean'],
      [{ 'align': [] }],
      
      ,// Remove formatting
    ],
  };

  export const QUILL_FORMATS= [
    'header', 'bold', 'italic', 'underline', 'strike', 'blockquote', 'list',
    'bullet', 'indent', 'link', 'image',
    'video', 'color', 'background', 'align',
  ];
