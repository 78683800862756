import OutsideClickHandler from "react-outside-click-handler"

export interface DeletedModalProps {
  setDeletedModalIsOpen: Function
  text: string
}

// styles:React.CSSProperties

export function DeletedModal({
  setDeletedModalIsOpen,
  text,
}: DeletedModalProps) {
  return (
    <>
      <div className="deleted_modal">
        <OutsideClickHandler
          onOutsideClick={() => {
            setDeletedModalIsOpen(false)
          }}
        >
          <div className="deleted_modal_contert">
            <p className="text">Are you sure you want to delete this {text}?</p>
            <div className="btn_content">
              <button
                className="delete_btn"
                onClick={() => {
                  setDeletedModalIsOpen(true)
                }}
              >
                Yes
              </button>
              <button
                className="cancel_btn"
                onClick={() => {
                  setDeletedModalIsOpen(false)
                }}
              >
                No
              </button>
            </div>
          </div>
        </OutsideClickHandler>
      </div>
    </>
  )
}
