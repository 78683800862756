import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit"
import thunkMiddleware from "redux-thunk"
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux"
import loginSlice from "./pages/login/slice/login"
import contentsSlice from "./pages/main/pages/contents/slice"
import languagesSlice from "./components/languages/slice"
import adminCurrentLanguageSlice from "./global-slice/current-language-slice"
import contentsByIdSlice from "./global-slice/get-content-by-Id-slice"
import addContentSlice from "./global-slice/add-content-slice"
import adminInformationSlice from "./global-slice/admin-information-slice"
import editContentSlice from "./global-slice/edit-content-slice"
import totalPaginationSlice from "./global-slice/total-paginator-page"
import contenMediaUploadSlice from "./modals/content-media-upload-modal/slice"
import getContentMediaSlice from "./global-slice/get-content-media-slice"
import deleteContentMediaSlice from "./global-slice/delete-content-media-slice"

export const store = configureStore({
  reducer: {
    login: loginSlice,
    adminInformation: adminInformationSlice,
    contents: contentsSlice,
    languages: languagesSlice,
    currentLanguage: adminCurrentLanguageSlice,
    contentsById: contentsByIdSlice,
    addContent: addContentSlice,
    editContent: editContentSlice,
    totalPagination: totalPaginationSlice,
    contentMediaUpload: contenMediaUploadSlice,
    getContentMedia: getContentMediaSlice,
    deleteContentMedia:deleteContentMediaSlice
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(thunkMiddleware),
})

export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>
