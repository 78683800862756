import { Button, Form, FormInstance, Input, Select, Upload } from "antd"
import {
  IAddContentFieldType,
  IContentByIdAsyncData,
  ICreateContentBody,
  IFilledDataType,
} from "../../../../models"
import { useEffect, useState } from "react"
import {
  fetchLanguagesAsync,
  languagesInformation,
} from "../../../../components/languages/slice"
import { ILanguageData } from "../../../../models/languages"
import { useNavigate, useParams } from "react-router-dom"

import { Loading } from "../../../../components"
import {
  contentDetailsSelector,
  resetContentDetailsData,
  fetchContentDetailsAsync,
} from "../../../../global-slice/get-content-by-Id-slice"
import {
  addContentErrorSelector,
  addContentStatusSelector,
  createContentAsync,
  updateContentStatus,
} from "../../../../global-slice/add-content-slice"

import {
  updateEditContentStatus,
  editContentStatusSelector,
  editContentErrorSelector,
  editContentAsync,
} from "../../../../global-slice/edit-content-slice"

import back from "../../../../assets/icons/back.png"
import { useAppDispatch, useAppSelector } from "../../../../store"
import { UploadFile } from "antd/es/upload/interface"
import { IContent } from "../../../../models/get-content-by-id"
import { RequestStatus } from "../../../../enums"
import {
  ValidateRequired,
  validateMaxCharacters,
  validateMinCharacters,
  validateStartWithoutSpace,
} from "../../../../validators"

import  _ from "lodash"
import ReactQuill from "react-quill"
import "react-quill/dist/quill.snow.css" // Import the styles
import { sleep } from "../../../../helpers"
import { ContentMediaUploadModal } from "../../../../modals"

import 'react-quill/dist/quill.snow.css';

import {QUILL_MODULES,QUILL_FORMATS} from '../../../../configs/index';

export function ContentDetails() {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [form] = Form.useForm()
  const [fakeForm] = Form.useForm()
  const params = useParams()

  const [mainImg, setMainImage] = useState<File>()
  const [mainImgfileList, setMainImgFileList] = useState<UploadFile[]>([])

  const [currentLanguage, setCurrentLanguage] = useState<number>()

  const [openUploadPhotoModal, setOpenUploadPhotoModal] =
    useState<boolean>(false)

  const availableLanguages =
    useAppSelector<ILanguageData[]>(languagesInformation)

  const [filledData, setFilledData] = useState<IFilledDataType[]>([])

  const contentDetails = useAppSelector<IContent>(contentDetailsSelector)

  const addContentStatus = useAppSelector<RequestStatus>(
    addContentStatusSelector,
  )
  const addContentErrorMessage = useAppSelector(addContentErrorSelector)

  const editContentStatus = useAppSelector<RequestStatus>(
    editContentStatusSelector,
  )
  const editContentErrorMessage = useAppSelector(editContentErrorSelector)

  const photoURL: string = import.meta.env.VITE_CONTENT_PHOTO_URL


  const updateFormValues = (data: IFilledDataType, form: FormInstance) => {
    for (const key in data) {
      form.setFieldValue(key, data[key as keyof IFilledDataType])
    }
  }

  const validateFilledData = async () => {
    for (let i = 0; i < filledData.length; i++) {
      const fData = filledData[i]
      updateFormValues(fData, fakeForm)
      await sleep(100)
      try {
        await fakeForm.validateFields()
      } catch (error) {
        form.setFieldsValue({
          language: fData.id,
        })
        onLanguageChange(fData.id)
        throw error
      }
    }
  }


  useEffect(() => {
    dispatch(fetchLanguagesAsync())
    dispatch(updateContentStatus(RequestStatus.EMPTY))
    dispatch(updateEditContentStatus(RequestStatus.EMPTY))
  }, [dispatch])

  useEffect(() => {
    setMainImgFileList([])
  }, [])

  useEffect(
    () => () => {
      resetContentDetailsData()
      return
    },
    [],
  )

  useEffect(() => {
    const fillEmptyDataForAllLanguages = () => {
      const data = availableLanguages.map((element: ILanguageData) => {
        return { id: element.id, title: "", description: "", address: "" }
      })
      setFilledData(data)
    }

    const setDefaultLanguage = () => {
      if (availableLanguages.length) {
        form.setFieldsValue({
          language: availableLanguages[0].id,
        })
        setCurrentLanguage(availableLanguages[0].id)
      }
    }

    setDefaultLanguage()
    if (!params.id) {
      fillEmptyDataForAllLanguages()
    }
  }, [availableLanguages, form, params])

  useEffect(() => {
    if (!params.id) {
      return
    }
    const data: IContentByIdAsyncData = {
      id: Number(params?.id),
      headers: "en",
    }
    dispatch(fetchContentDetailsAsync(data))
  }, [params, dispatch])

  useEffect(() => {
    if (contentDetails?.mainPhoto && params?.id) {
      setMainImgFileList([
        {
          uid: "-1",
          name: "image.png",
          status: "done",
          url: photoURL + contentDetails?.mainPhoto?.path,
        },
      ])
    } else {
      setMainImgFileList([])
    }
  }, [contentDetails, params, photoURL])


  useEffect(() => {
    if (_.isEmpty(contentDetails) || _.isEmpty(params)) {
      return
    }
    const filledData = contentDetails.translations.map((element) => {
      return {
        id: element.language.id,
        address: element.address,
        description: element.description,
        title: element.title,
      }
    })
    const currentData = filledData.find(
      (e) => e.id === form.getFieldValue("language"),
    )

    form.setFieldsValue({
      mainPhoto: contentDetails?.mainPhoto?.path,
      title: currentData?.title,
      address: currentData?.address,
      description: currentData?.description,
    })
    setFilledData([...filledData])
  }, [contentDetails, filledData.length, form])

  useEffect(() => {
    if (addContentStatus === RequestStatus.IDLE) {
      resetContentDetailsData()
      navigate("/contents")
      dispatch(updateContentStatus(RequestStatus.EMPTY))
      setMainImgFileList([])

    }
  }, [dispatch, navigate, addContentStatus])

  useEffect(() => {
    if (editContentStatus === RequestStatus.IDLE) {
      resetContentDetailsData()
      navigate("/contents")
      dispatch(updateEditContentStatus(RequestStatus.EMPTY))
      setMainImgFileList([])
    }
  }, [dispatch, navigate, editContentStatus])

  const onFinish = async (values: IAddContentFieldType) => {
    const { title, description, address } = values
    const dataIndex = filledData.findIndex(
      (element) => element.id === currentLanguage,
    )
    filledData[dataIndex] = {
      id: currentLanguage as number,
      title,
      description,
      address,
    }
    setFilledData([...filledData])
    await validateFilledData()

    const contentData: ICreateContentBody = {
      data: filledData,
      mainPhoto: mainImg as File,
    }

    if (params && params?.id) {
      dispatch(editContentAsync({ id: Number(params.id), ...contentData }))


    } else {
      dispatch(createContentAsync(contentData))
    }
  }

  const onLanguageChange = (selectedLanguage: number) => {
    const { title, description, address } = form.getFieldsValue()
    const dataIndex = filledData.findIndex(
      (element) => element.id === currentLanguage,
    )
    filledData[dataIndex] = {
      id: currentLanguage as number,
      title,
      description,
      address,
    }
    const currentData = filledData.find(
      (e) => e.id === selectedLanguage,
    ) as IFilledDataType
    updateFormValues(currentData, form)
    setCurrentLanguage(selectedLanguage)
    setFilledData([...filledData])
  }

  const mainImgOnPreview = async (file: UploadFile) => {
    let src = file.url as string
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader()
        reader.readAsDataURL(file.originFileObj as Blob)
        reader.onload = () => resolve(reader.result as string)
      })
    }
    const image = new Image()
    image.src = src
    const imgWindow: any = window.open(src)
    imgWindow.document.write(image.outerHTML)
  }

  const mainImgbeforeUpload = (file: any) => {
    setMainImage(file)
    return false
  }

  const mainImgonChange = ({ fileList: newFileList }: any) => {
    setMainImgFileList(newFileList)
  }

  const mainFile = (e: any) => {
    if (Array.isArray(e)) {
      return e
    }
    return e && e.fileList
  }

  return (
    <>
      <div>
        <Form form={fakeForm} name="basic" style={{ display: "none" }}>
          <Form.Item<IAddContentFieldType>
            style={{ marginTop: "50px", padding: "5px 0" }}
            label="News Title"
            name="title"
            rules={[
              {
                validator: (_, value) => ValidateRequired(_, value, "title"),
              },
              {
                validator: (_, value) =>
                  validateStartWithoutSpace(_, value, "Title"),
              },
              {
                validator: (_, value) =>
                  validateMinCharacters(_, value, "Title", 5),
              },
              {
                validator: (_, value) =>
                  validateMaxCharacters(_, value, "Title", 2000),
              },
            ]}
          >
            {/* <Input className="input" /> */}
            <ReactQuill
              style={{ width: "100%" }}
              theme="snow"
              className="input"
              modules={QUILL_MODULES}
              formats={QUILL_FORMATS}
            />
          </Form.Item>

          <Form.Item<IAddContentFieldType>
            className='form-description'
            style={{ marginTop: "50px", padding: "5px 0" }}
            label="Description"
            name="description"
            rules={[
              {
                validator: (_, value) =>
                  ValidateRequired(_, value, "description"),
              },
              {
                validator: (_, value) =>
                  validateStartWithoutSpace(_, value, "Description"),
              },
              {
                validator: (_, value) =>
                  validateMinCharacters(_, value, "Description", 5),
              },
              {
                validator: (_, value) =>
                  validateMaxCharacters(_, value, "Description", 10000),
              },
            ]}
          >
            <ReactQuill
              style={{ width: "100%" }}
              theme="snow"
              className="textAreat_input"
              modules={QUILL_MODULES}
              formats={QUILL_FORMATS}
            />
          </Form.Item>
          <Form.Item<IAddContentFieldType>
            style={{ marginTop: "50px", padding: "5px 0" }}
            label="Address"
            name="address"
            rules={[
              {
                validator: (_, value) => ValidateRequired(_, value, "address"),
              },
              {
                validator: (_, value) =>
                  validateStartWithoutSpace(_, value, "Address"),
              },
              {
                validator: (_, value) =>
                  validateMinCharacters(_, value, "Address", 5),
              },
              {
                validator: (_, value) =>
                  validateMaxCharacters(_, value, "Address", 80),
              },
            ]}
          >
            <Input className="input" />
            
          </Form.Item>
        </Form>
      </div>
      <div className="add_form_content">
        <img
          alt="Back Icon"
          src={back}
          className="back_icon"
          onClick={() => {
            navigate("/contents")
          }}
        />
        <Form
          form={form}
          name="basic"
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 20 }}
          initialValues={{ remember: true }}
          autoComplete="off"
          onFinish={onFinish}
        >
          <p className="error_message">{addContentErrorMessage}</p>

          {params ? (
            <p className="error_message">{editContentErrorMessage}</p>
          ) : null}
          <div className="content_header">
            <Form.Item<IAddContentFieldType>
              name="language"
              rules={[
                {
                  required: true,
                  message: "",
                },
              ]}
            >
              <Select
                className="lng_select"
                style={{ width: "150px" }}
                onChange={onLanguageChange}
              >
                {availableLanguages.map((element: ILanguageData) => {
                  return (
                    <Select.Option value={element?.id}>
                      {element?.name}
                    </Select.Option>
                  )
                })}
              </Select>
            </Form.Item>
            <button
              type="button"
              className="upload_img_btn"
              onClick={() => {
                setOpenUploadPhotoModal(!openUploadPhotoModal)
              }}
            >
              Upload Images
            </button>
          </div>
          <Form.Item<IAddContentFieldType>
            style={{ marginTop: "50px", padding: "5px 0" }}
            label="News Title"
            name="title"
            rules={[
              {
                validator: (_, value) => ValidateRequired(_, value, "title"),
              },
              {
                validator: (_, value) =>
                  validateStartWithoutSpace(_, value, "Title"),
              },
              {
                validator: (_, value) =>
                  validateMinCharacters(_, value, "Title", 5),
              },
              {
                validator: (_, value) =>
                  validateMaxCharacters(_, value, "Title", 2000),
              },
            ]}
          >
            {/* <Input className="input" /> */}
            <ReactQuill
              style={{ width: "100%" }}
              theme="snow"
              className="textAreat_input"
              modules={QUILL_MODULES}
              formats={QUILL_FORMATS}
            />
          </Form.Item>

          <Form.Item<IAddContentFieldType>
            className='form-description'
            style={{ marginTop: "50px", padding: "5px 0", height: 'fit-content' }}
            label="Description"
            name="description"
            rules={[
              {
                validator: (_, value) =>
                  ValidateRequired(_, value, "description"),
              },
              {
                validator: (_, value) =>
                  validateStartWithoutSpace(_, value, "Description"),
              },
              {
                validator: (_, value) =>
                  validateMinCharacters(_, value, "Description", 5),
              },
              {
                validator: (_, value) =>
                  validateMaxCharacters(_, value, "Description", 10000),
              },
            ]}
          >
            <ReactQuill
              style={{ width: "100%" }}
              theme="snow"
              className="textAreat_input"
              modules={QUILL_MODULES}
              formats={QUILL_FORMATS}

            />
          </Form.Item>

          <Form.Item<IAddContentFieldType>
            style={{ marginTop: "50px", padding: "5px 0" }}
            label="Address"
            name="address"
            rules={[
              {
                validator: (_, value) => ValidateRequired(_, value, "Address"),
              },
              {
                validator: (_, value) =>
                  validateStartWithoutSpace(_, value, "Address"),
              },
              {
                validator: (_, value) =>
                  validateMinCharacters(_, value, "Address", 5),
              },
              {
                validator: (_, value) =>
                  validateMaxCharacters(_, value, "Address", 80),
              },
            ]}
          >
            <Input className="input" />
          </Form.Item>
          <Form.Item<IAddContentFieldType>
            style={{ marginTop: "50px", padding: "5px 0" }}
            label="Main Photo"
            name="mainPhoto"
            getValueFromEvent={mainFile}
            rules={[
              {
                validator: (_, value) =>
                  ValidateRequired(_, value, "Main Photo"),
              },
            ]}
          >
            <Upload
              listType="picture-card"
              fileList={mainImgfileList}
              onChange={mainImgonChange}
              onPreview={mainImgOnPreview}
              beforeUpload={(file: File) => mainImgbeforeUpload(file)}
              accept=".png,.jpg"
            >
              {mainImgfileList.length < 1 && "+ Upload"}
            </Upload>
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button className="add_btn" htmlType="submit">
              {params && Number(params?.id) ? "Edit" : "Create"}
              {addContentStatus === RequestStatus.LOADING ? <Loading /> : null}

              {params && editContentStatus === RequestStatus.LOADING ? (
                <Loading />
              ) : null}
            </Button>
          </Form.Item>
        </Form >
      </div >
      {
        openUploadPhotoModal ? (
          <ContentMediaUploadModal
            setUploadModalIsOpen={(isOpen: boolean) => {
              setOpenUploadPhotoModal(isOpen)
            }}
          />
        ) : null}
    </>
  )
}
