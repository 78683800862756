import { Table } from "antd";

import type { ColumnsType } from 'antd/es/table';

interface DataType {
    key: React.Key;
    name: string;
    age: number | null;
    address: string;
  }
  
  const columns: ColumnsType<DataType> = [
    {
      title: 'Name',
      dataIndex: 'name',

    },
    {
      title: 'Age',
      dataIndex: 'age'
    },
    {
      title: 'Address',
      dataIndex: 'address',
    },
  ];
  
  const data: DataType[] = [
    {
      key: '1',
      name: '',
      age: null,
      address: '',
    },
    {
      key: '2',
      name: '',
      age: null,
      address: '',
    },
    {
      key: '3',
      name: '',
      age: null,
      address: '',
    },
    {
      key: '4',
      name: '',
      age: null,
      address: '',
    },
  ];

export function SkeletonLoading() {
    return (

        <div className="skeleton_container">
               <div className="skeleton_header">
                <div className="language_btn"></div>
                <div className="add_btn"></div>
            </div>
            <Table columns={columns} dataSource={data}/>
        </div>
    )
}