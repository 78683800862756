import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { AxiosResponse } from "axios"
import {
  IContentMediaUploadState,
  IGeneralState,
  IContentMediaUploadFieldType,
} from "../../../models"
import { RequestStatus } from "../../../enums"
import { CreateContenMediaUpload } from "../../../services/Api"

const initialContentMediaUploadState: IContentMediaUploadState = {
  status: RequestStatus.EMPTY,
  error: null,
}

export const contentMediaUploadAsync = createAsyncThunk(
  "content/media/upload",
  async (
    content: IContentMediaUploadFieldType,
    { fulfillWithValue, rejectWithValue },
  ) => {
    try {
      const formData = new FormData()
      const { name, photo } = content
      formData.append("name", name)
      formData.append("photo", photo)

      const response: AxiosResponse<any> = await CreateContenMediaUpload(
        formData,
      )
      return fulfillWithValue(response.data)
    } catch (error: any) {
      return rejectWithValue(error.response.data.errors[0].message)
    }
  },
)

export const contenMediaUploadSlice = createSlice({
  name: "content-media-upload",
  initialState: initialContentMediaUploadState,
  reducers: {
    updateContentMediaUploadStatus(
      state,
      action: PayloadAction<RequestStatus>,
    ) {
      state.status = action.payload
      state.error = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      contentMediaUploadAsync.pending,
      (state: IContentMediaUploadState) => {
        state.status = RequestStatus.LOADING
      },
    )
    builder.addCase(
      contentMediaUploadAsync.fulfilled,
      (state: IContentMediaUploadState) => {
        state.status = RequestStatus.IDLE
      },
    )
    builder.addCase(
      contentMediaUploadAsync.rejected,
      (state: IContentMediaUploadState, action: any) => {
        state.status = RequestStatus.ERROR
        state.error = action.payload
      },
    )
  },
})

export const { updateContentMediaUploadStatus } = contenMediaUploadSlice.actions

export const contentMediaUploadStatusSelector = (state: IGeneralState) =>
  state.contentMediaUpload.status
export const contentMediaUploadErrorSelector = (state: IGeneralState) =>
  state.contentMediaUpload.error

export default contenMediaUploadSlice.reducer
