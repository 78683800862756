import "./assets/css/style.scss";
import { Routes } from "./routes/routes";


function App() {
  return (
      <div className="App">
      <Routes/>
    </div>
  )
}

export default App
