import { useEffect } from "react"
import { MainRoutes } from "./main-routes"
import { AuthRoutes } from "./auth-routes"
import { accessTokenSelector } from "../pages/login/slice/login"
import { useAppSelector } from "../store"

export function Routes() {
  const accessToken:string | null = useAppSelector(accessTokenSelector)

  useEffect(() => {}, [accessToken])

  return (
    <>
      <div>{accessToken ? <MainRoutes /> : <AuthRoutes />}</div>
    </>
  )
}
