export const ERROR_MESSAGES: { [key: string]: string } = {
  err_admin_not_exists: "The user not found, wrong email or password.",
  err_password_min_length: "Password must be minimum 8 characters.",
  err_password_max_length: "Password must be a maximum of 60 characters.",
  err_content_title_already_exists: "Title already exists",
  err_the_minimum_dimensions_for_photo_is_700_x_700_px:
    "The minimum dimensions for photo is 700x700px",
  err_the_minimum_dimensions_for_main_photo_is_700_x_700_px:
    "The minimum dimensions for main photo is 700x700px",
}
