import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

export function Loading(){
    const antIcon = <LoadingOutlined style={{ fontSize: 24,marginLeft:'10px' }} spin />
    return (
        <>
        <Spin indicator={antIcon} />
        </>
    )
}