
import { Layout } from "antd";

import logo from "../../assets/images/padcLogo.png";
import admin_icon from "../../assets/icons/admin_icon.png";

import { SearchComponent, SideNav } from "../../components";

import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../store";
import { IAdminData } from "../../models";
import { adminInformationSelector, increamentAdminInformationAsync } from "../../global-slice/admin-information-slice";

export function Main() {
  const { Header, Sider, Content } = Layout;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [search, setSearch] = useState('');
  
  const adminInformation = useAppSelector<IAdminData>(adminInformationSelector)


  useEffect(() => {
    if(location.pathname === '/contents'){
      const timeoutId = setTimeout(() => {
        navigate({
          pathname: '/contents',
          search: `?search=${search}`,
        });
      }, 500);
      return () => clearTimeout(timeoutId);
    }
   
  }, [search])

  
  useEffect(() => {
    dispatch(increamentAdminInformationAsync())
  }, [dispatch])


  return (
    <>
      <Layout className="layout">
        <Header className="header">
          <div className="header_content">
            <img className="logo" src={logo} />
            <div  className="seach_content">
            {location.pathname === '/contents' ? <div className="seach_content_description">
              <SearchComponent
                sendSerchValue={(event: string) => {
                  setSearch(event);
                }}
              /></div> : <></>}
            <div className="seach_content_description">
              <p>Welcome ({adminInformation?.email})</p>
              <img src={admin_icon} />
            </div>
            </div>
          </div>
        </Header>
        <Layout hasSider>
          <Sider className="sider">
            <SideNav />
          </Sider>
          <Content className="content">
            <div className="main_outlet">
              <Outlet />
            </div>
          </Content>
        </Layout>
      </Layout>
    </>
  )
}
