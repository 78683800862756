import { Button, Form, Input } from "antd"
import { ILoginFieldDataType } from "../../models"

import logo from "../../assets/images/padcLogo.png"
import { Loading } from "../../components"

import {
  loginAsync,
  loginErrorSelector,
  loginStatusSelector,
  updateLoginError,
} from "./slice/login"
import { RequestStatus } from "../../enums"
import {
  ValidateRequired,
  validateMaxCharacters,
  validateMinCharacters,
  validateStartWithoutSpace,
} from "../../validators"
import { useAppDispatch, useAppSelector } from "../../store"
import { useEffect, useState } from "react"

export function Login() {
  const dispatch = useAppDispatch()
  const loginStatus: string = useAppSelector(loginStatusSelector)
  const errorMessage: string | null = useAppSelector(loginErrorSelector)
  const [loginErrorMessage, setLoginErrorMessage] = useState<string | null>()

  const onFinish = (values: ILoginFieldDataType) => {
    dispatch(loginAsync(values))
    dispatch(updateLoginError())
  }

  useEffect(() => {
    setLoginErrorMessage(errorMessage)
  }, [errorMessage])

  const handleFieldFocus = (fieldName: string) => {
    if (errorMessage && fieldName) {
      setLoginErrorMessage(null)
    }
  }

  return (
    <>
      <div className="login_page">
        <div className="login_page_main">
          <div className="form_content">
            <p className="error_message">{loginErrorMessage}</p>
            <Form
              name="basic"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
              style={{ maxWidth: 600 }}
              initialValues={{ remember: true }}
              onFinish={onFinish}
              autoComplete="off"
            >
              <Form.Item<ILoginFieldDataType>
                style={{ marginTop: "50px", padding: "5px 0" }}
                name="login"
                rules={[
                  {
                    type: "email",
                    message: "The input is not valid email!",
                  },
                  {
                    validator: (_, value) =>
                      validateStartWithoutSpace(_, value, "Email"),
                  },
                  {
                    validator: (_, value) =>
                      ValidateRequired(_, value, "email"),
                  },
                ]}
              >
                <Input
                  className="input"
                  placeholder="Email"
                  onFocus={() => handleFieldFocus("login")}
                />
              </Form.Item>

              <Form.Item<ILoginFieldDataType>
                style={{ marginTop: "50px", padding: "5px 0" }}
                name="password"
                rules={[
                  {
                    validator: (_, value) =>
                      ValidateRequired(_, value, "password"),
                  },
                  {
                    validator: (_, value) =>
                      validateStartWithoutSpace(_, value, "Password"),
                  },
                  {
                    validator: (_, value) =>
                      validateMinCharacters(_, value, "Password", 8),
                  },
                  {
                    validator: (_, value) =>
                      validateMaxCharacters(_, value, "Password", 60),
                  },
                ]}
              >
                <Input.Password
                  className="input"
                  placeholder="Password"
                  onFocus={() => handleFieldFocus("password")}
                />
              </Form.Item>

              <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                <Button className="login_btn" htmlType="submit">
                  Log In
                  {loginStatus === RequestStatus.LOADING ? <Loading /> : null}
                </Button>
              </Form.Item>
            </Form>
          </div>
          <div className="admin_panel_main">
            <div className="admin_panel">
              <h1>WELCOME TO</h1>
              <img src={logo} />
              <h2>ADMIN PANEL</h2>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
