import { createSlice } from "@reduxjs/toolkit"
import { IGeneralState } from "../../models"

export interface ICurrentLanguageState {
  currentLanguage: string
}

const initialAdminCurrentLanguageState: ICurrentLanguageState = {
  currentLanguage: "en",
}

export const adminCurrentLanguageSlice = createSlice({
  name: "currentLanguage",
  initialState: initialAdminCurrentLanguageState,
  reducers: {
    updateCurrentLanguage: {
      reducer(state: ICurrentLanguageState, action: any) {
        state.currentLanguage = action.payload
      },
      prepare(newLanguage: string) {
        // The prepare callback allows you to pass additional arguments to the reducer
        return { payload: newLanguage }
      },
    },
  },
})

export const adminCurrentLanguageSelector = (state: IGeneralState) =>
  state.currentLanguage.currentLanguage
export const { updateCurrentLanguage } = adminCurrentLanguageSlice.actions

export default adminCurrentLanguageSlice.reducer
