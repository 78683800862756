import OutsideClickHandler from "react-outside-click-handler"
import { Button, Form, Input, Upload } from "antd"
import { UploadFile } from "antd/es/upload/interface"
import {
  ValidateRequired,
  validateMaxCharacters,
  validateMinCharacters,
  validateStartWithoutSpace,
} from "../../validators"
import { useEffect, useState } from "react"
import { ContentMediaItem, Loading } from "../../components"

import { Col, Row } from "antd"
import {
  IContentMediaUploadFieldType,
  IGetContentMediaData,
} from "../../models"
import { RequestStatus } from "../../enums"
import { useAppDispatch, useAppSelector } from "../../store"
import {
  contentMediaUploadAsync,
  contentMediaUploadErrorSelector,
  contentMediaUploadStatusSelector,
  updateContentMediaUploadStatus,
} from "./slice"
import {
  contentMediaInformation,
  fetchGetContentMediaAsync,
} from "../../global-slice/get-content-media-slice"

import _ from "lodash"
import { deleteContentMediaStatusSelector, updateCondentMediaDeletedStatus } from "../../global-slice/delete-content-media-slice"

export interface UploadImageModalProps {
  setUploadModalIsOpen: Function
}

export function ContentMediaUploadModal({
  setUploadModalIsOpen,
}: UploadImageModalProps) {
  const dispatch = useAppDispatch()
  const { Search } = Input
  const [form] = Form.useForm()
  const [img, setImage] = useState<File>()
  const [fileList, setFileList] = useState<UploadFile[]>([])
  const contentMediaUploadStatus = useAppSelector<RequestStatus>(
    contentMediaUploadStatusSelector,
  )
  const errorMessage = useAppSelector(contentMediaUploadErrorSelector)

  const contentMediaInformationData = useAppSelector(contentMediaInformation)

  const deleteContentMediaStatus = useAppSelector(deleteContentMediaStatusSelector)
  const [contentMediaInfo, setContentMediaInfo] = useState<IGetContentMediaData[]>([])

  useEffect(() => {
    dispatch(updateContentMediaUploadStatus(RequestStatus.EMPTY))
    dispatch(updateCondentMediaDeletedStatus(RequestStatus.EMPTY))
    setFileList([])
  }, [dispatch])

  useEffect(() => {
    dispatch(fetchGetContentMediaAsync(""))
  }, [])

  useEffect(() => {
    if (_.isEmpty(contentMediaInformationData)) {
      return
    }
    setContentMediaInfo(contentMediaInformationData)
  }, [contentMediaInformationData])

  useEffect(() => {
    if (
      contentMediaUploadStatus &&
      contentMediaUploadStatus === RequestStatus.IDLE
    ) {
      form.resetFields()
      setFileList([])
      dispatch(updateContentMediaUploadStatus(RequestStatus.EMPTY))
      dispatch(updateCondentMediaDeletedStatus(RequestStatus.EMPTY))
      dispatch(fetchGetContentMediaAsync(""))
    }
  }, [dispatch, contentMediaUploadStatus])

  useEffect(()=>{
if(deleteContentMediaStatus && deleteContentMediaStatus === RequestStatus.IDLE){
  dispatch(fetchGetContentMediaAsync(""))
  dispatch(updateCondentMediaDeletedStatus(RequestStatus.EMPTY))
}
  },[dispatch,deleteContentMediaStatus]);


  const onFinish = (values: IContentMediaUploadFieldType) => {
    const contentData: IContentMediaUploadFieldType = {
      name: values.name,
      photo: img as File,
    }
    dispatch(contentMediaUploadAsync(contentData))
  }

  const imgOnChange = ({ fileList: newFileList }: any) => {
    setFileList(newFileList)
  }

  const imgOnPreview = async (file: UploadFile) => {
    let src = file.url as string
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader()
        reader.readAsDataURL(file.originFileObj as Blob)
        reader.onload = () => resolve(reader.result as string)
      })
    }
    const image = new Image()
    image.src = src
    const imgWindow: any = window.open(src)
    imgWindow.document.write(image.outerHTML)
  }

  const beforeUpload = (file: File) => {
    setImage(file)
    return false
  }

  const imageFile = (e: any) => {
    if (Array.isArray(e)) {
      return e
    }
    return e && e.fileList
  }

  const searchContentMedia = (event: any) => {
    dispatch(fetchGetContentMediaAsync(event.target.value))
  }

  return (
    <>
      <div className="photo_upload_modal">
        <OutsideClickHandler
          onOutsideClick={() => {
            setUploadModalIsOpen(false)
          }}
        >
          <div className="photo_upload_modal_contert">
            <Form
              style={{ width: "50%" }}
              form={form}
              name="basic"
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 20 }}
              initialValues={{ remember: true }}
              autoComplete="off"
              onFinish={onFinish}
            >
              <p className="error_message">{errorMessage}</p>
              <div className="name_input_content">
                <Form.Item<IContentMediaUploadFieldType>
                  style={{ padding: "5px 0" }}
                  label="Name"
                  name="name"
                  rules={[
                    {
                      validator: (_, value) =>
                        ValidateRequired(_, value, "name"),
                    },
                    {
                      validator: (_, value) =>
                        validateStartWithoutSpace(_, value, "Name"),
                    },
                    {
                      validator: (_, value) =>
                        validateMinCharacters(_, value, "Name", 5),
                    },
                    {
                      validator: (_, value) =>
                        validateMaxCharacters(_, value, "Name", 2000),
                    },
                  ]}
                >
                  <Input className="input" />
                </Form.Item>
              </div>

              <Form.Item<IContentMediaUploadFieldType>
                style={{ marginTop: "50px", padding: "5px 0" }}
                label="Photo"
                name="photo"
                getValueFromEvent={imageFile}
                rules={[
                  {
                    validator: (_, value) =>
                      ValidateRequired(_, value, "Photo"),
                  },
                ]}
              >
                <Upload
                  listType="picture-card"
                  fileList={fileList}
                  onChange={imgOnChange}
                  onPreview={imgOnPreview}
                  beforeUpload={(file: File) => beforeUpload(file)}
                  accept=".png,.jpg"
                >
                  {fileList.length < 1 && "+ Upload"}
                </Upload>
              </Form.Item>

              <Form.Item>
                <Button className="add_btn" htmlType="submit">
                  Upload Image
                  {contentMediaUploadStatus === RequestStatus.LOADING ? (
                    <Loading />
                  ) : null}
                </Button>
              </Form.Item>
            </Form>
            <div className="article_description_items">
              <Search
                className="search_input"
                placeholder="Search"
                onChange={searchContentMedia}
              />
              <div className="images_row">
                <Row gutter={16}>
                  {contentMediaInfo?.map(
                    (element: IGetContentMediaData, index) => {
                      return (
                        <Col span={8} key={index + 1}>
                          <ContentMediaItem element={element} />
                        </Col>
                      )
                    },
                  )}
                </Row>
              </div>
            </div>
          </div>
        </OutsideClickHandler>
      </div>
    </>
  )
}
