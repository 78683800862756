import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom"

import { Main } from "../pages/index"
import { ContentDetails, Contents } from "../pages/main/pages"

export function MainRoutes() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Main />}>
            <Route path="/" element={<Navigate to="/contents" />} />
            <Route path="/contents" element={<Contents />} />
            <Route path="/contents/add" element={<ContentDetails />} />
            <Route path="/contents/:id" element={<ContentDetails />} />
          </Route>
          <Route path="*" element={<Navigate to="/contents" />} />
        </Routes>
      </BrowserRouter>
    </>
  )
}
