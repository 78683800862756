import { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";

import logout from '../../assets/icons/logOut.png';
import content from '../../assets/icons/content.png';

interface LinkItem {
    name: string;
    icon: string;
    navigate: string;
};
export function SideNav() {
    const navigate = useNavigate();

    const [linkItem, setLinkItem] = useState<LinkItem[]>([
        { name: 'Contents', icon: content, navigate: '/contents' },
    ]);

    return (
        <>
            <div className="sidenav_content">
                <ul className="nav__block">
                    {
                        linkItem.map((element: LinkItem, index: number) => {
                            return <li className="nav__list" key={index + 1}
                            >
                                <NavLink to={element?.navigate}
                                    className={({ isActive }) =>
                                        [
                                            "navLink",
                                            isActive ? "activeClass" : null,
                                        ]
                                            .filter(Boolean)
                                            .join(" ")
                                    }
                                >
                                    <img className="icon" src={element?.icon} />
                                    <span className="nav__text">{element?.name}</span>
                                </NavLink>

                            </li>
                        })
                    }
                    <li className="log_out_nav__list"
                        onClick={(() => {
                            localStorage.removeItem("accessToken");
                            localStorage.removeItem('refreshToken');
                            window.location.href = "/login";
                        })}>
                        <img className="icon" src={logout} />
                        <span className="nav__text">Log Out</span>
                    </li>
                </ul>
            </div>
        </>
    )
}