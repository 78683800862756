import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { AxiosResponse } from "axios"

import { EditContent } from "../../services/Api"
import {
  IEditContentState,
  IFilledDataType,
  IGeneralState,
  IUpdateContentBody,
} from "../../models"
import { RequestStatus } from "../../enums"

const initialEditContenttState: IEditContentState = {
  status: RequestStatus.EMPTY,
  error: null,
}

export const editContentAsync = createAsyncThunk(
  "edit/content",
  async (
    content: IUpdateContentBody,
    { fulfillWithValue, rejectWithValue },
  ) => {
    try {
      const formData = new FormData()
      const { data, mainPhoto, id } = content
      data.forEach((element: IFilledDataType, index: number) => {
        formData.append(`content[${index}][languageId]`, element?.id.toString())
        formData.append(`content[${index}][title]`, element?.title)
        formData.append(`content[${index}][description]`, element?.description)
        formData.append(`content[${index}][address]`, element?.address)
      })
      // if (img) {
      //   formData.append("photo", img)
      // }
      if (mainPhoto) {
        formData.append("mainPhoto", mainPhoto)
      }
      const response: AxiosResponse<any> = await EditContent(id, formData)
      return fulfillWithValue(response.data)
    } catch (error: any) {
      return rejectWithValue(error.response.data.errors[0].message)
    }
  },
)
export const editContentSlice = createSlice({
  name: "edit-content",
  initialState: initialEditContenttState,
  reducers: {
    updateEditContentStatus(state, action: PayloadAction<RequestStatus>) {
      state.status = action.payload
      state.error = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(editContentAsync.pending, (state: IEditContentState) => {
      state.status = RequestStatus.LOADING
    })
    builder.addCase(editContentAsync.fulfilled, (state: IEditContentState) => {
      state.status = RequestStatus.IDLE
    })
    builder.addCase(
      editContentAsync.rejected,
      (state: IEditContentState, action: any) => {
        state.status = RequestStatus.ERROR
        state.error = action.payload
      },
    )
  },
})

export const { updateEditContentStatus } = editContentSlice.actions

export const editContentStatusSelector = (state: IGeneralState) =>
  state.editContent.status
export const editContentErrorSelector = (state: IGeneralState) =>
  state.editContent.error

export default editContentSlice.reducer
