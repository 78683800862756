export const validateStartWithoutSpace = (
  _: any,
  value: string,
  title: string,
) => {
  // Check if the first character is a space
  if (value.charAt(0) === " ") {
    return Promise.reject(`${title} should not start with a space.`)
  }
  return Promise.resolve()
}
