import {
  ILoginFieldDataType,
  ILoginResponse,
  IAdminInformaionResponse,
  IContentResponse,
} from "../models"
import { IContentByIdResponse } from "../models/get-content-by-id"
import { ILanguageResponse } from "../models/languages"

import { api } from "./Base"

export const Login = (body: ILoginFieldDataType) => {
  return api.post<ILoginResponse>("auth/admin/login", body)
}

export const GetAdmin = () => {
  return api.get<IAdminInformaionResponse>("auth/admin")
}

export const GetContent = (
  search: string,
  headers: string,
  take: number,
  page?: number,
) => {
  return api.get<IContentResponse>("content", {
    headers: {
      "accept-language": headers,
    },
    params: {
      search,
      take,
      page,
      order: "DESC",
    },
  })
}
export const DeleteContentItem = (id: number) => {
  return api.delete(`content/${id}`)
}

export const GetLanguages = () => {
  return api.get<ILanguageResponse>("languages")
}

export const CreateContent = (formData: FormData) => {
  return api.post("content", formData)
}

export const GetContentById = (id: number, headers: string) => {
  return api.get<IContentByIdResponse>(`content/${id}`, {
    headers: {
      "accept-language": headers,
    },
  })
}

export const EditContent = (id: number, formData: FormData) => {
  return api.put(`content/${id}`, formData)
}

export const CreateContenMediaUpload = (formData: FormData) => {
  return api.post("content/photo", formData)
}

export const GetContentMedia = (search: string) => {
  return api.get("content/photos", {
    params: {
      search,
    },
  })
}

export const DeleteContentMedia = (id:number)=>{
  return api.delete(`content/photo/${id}`);
}