import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { AxiosResponse } from "axios"

import { GetLanguages } from "../../../services/Api"
import { ILanguageResponse, ILanguagesState } from "../../../models/languages"
import { IGeneralState } from "../../../models"

const initiallanguagesState: ILanguagesState = {
  data: [],
}

export const fetchLanguagesAsync = createAsyncThunk(
  "get/languages",
  async (_, { fulfillWithValue, rejectWithValue }) => {
    try {
      const response: AxiosResponse<ILanguageResponse> = await GetLanguages()
      return fulfillWithValue(response.data)
    } catch (error: any) {
      return rejectWithValue(error.response.data.errors[0].message)
    }
  },
)

export const languagesSlice = createSlice({
  name: "languages",
  initialState: initiallanguagesState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      fetchLanguagesAsync.fulfilled,
      (state: ILanguagesState, action: any) => {
        state.data = action.payload
      },
    )
  },
})

export const languagesInformation = (state: IGeneralState) =>
  state.languages.data

export default languagesSlice.reducer
