import { DeleteOutlined } from "@ant-design/icons"
import { Card } from "antd"
import { IGetContentMediaData } from "../../models"
import { useAppDispatch } from "../../store"
import { fetchDeleteContentMediaAsync } from "../../global-slice/delete-content-media-slice"

export interface ContentMediaItemProps {
  element: IGetContentMediaData
}

export function ContentMediaItem(element: ContentMediaItemProps) {
  const dispatch = useAppDispatch();
  const { Meta } = Card
  const photoURL: string = import.meta.env.VITE_CONTENT_PHOTO_URL

  return (
    <>
      <div className="description_photo_item">
        <Card
          cover={
            <img
              alt="example"
              className="img_item"
              src={photoURL + element?.element?.path}
            />
          }
          actions={[<DeleteOutlined key="delete" onClick={() => {
            dispatch(fetchDeleteContentMediaAsync(element?.element?.id))
          }} />]}
        >
          <Meta title={element?.element?.name} />
        </Card>
      </div>
    </>
  )
}
