import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { AxiosResponse } from "axios"

import { GetAdmin } from "../../services/Api"
import {
  IAdminInformaionResponse,
  IAdminInformationState,
  IGeneralState,
} from "../../models"

const initialAdminInformationState: IAdminInformationState = {
  data: {
    email: "",
    id: null,
    name: "",
  },
}

export const increamentAdminInformationAsync = createAsyncThunk(
  "get/admin",
  async (_, { fulfillWithValue, rejectWithValue }) => {
    try {
      const response: AxiosResponse<IAdminInformaionResponse> = await GetAdmin()
      return fulfillWithValue(response.data)
    } catch (error: any) {
      return rejectWithValue(error.response.data.errors[0].message)
    }
  },
)

export const adminInformationSlice = createSlice({
  name: "get-admin",
  initialState: initialAdminInformationState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      increamentAdminInformationAsync.fulfilled,
      (state: IAdminInformationState, action: any) => {
        state.data = action?.payload
      },
    )
  },
})

export const adminInformationSelector = (state: IGeneralState) =>
  state?.adminInformation?.data || {}

export default adminInformationSlice.reducer
