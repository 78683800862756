import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { AxiosResponse } from "axios"

import {
  IContentResponse,
  IContentsAsyncData,
  IContentsState,
  IGeneralState,
} from "../../../../../models"

import { GetContent } from "../../../../../services/Api"
import { RequestStatus } from "../../../../../enums"

const initialContentState: IContentsState = {
  data: { data: [], meta: {} },
  status: RequestStatus.EMPTY,
}

export const fetchContentsAsync = createAsyncThunk(
  "get/contents",
  async (
    contentsData: IContentsAsyncData,
    { fulfillWithValue, rejectWithValue },
  ) => {
    const search = contentsData.search
    const headers = contentsData.headers
    const take = contentsData.take
    const page = Number(contentsData.page)

    try {
      const response: AxiosResponse<IContentResponse> = await GetContent(
        search,
        headers,
        take,
        page,
      )
      return fulfillWithValue(response?.data)
    } catch (error: any) {
      return rejectWithValue(error.response.data.errors[0].message)
    }
  },
)

export const contentsSlice = createSlice({
  name: "contents",
  initialState: initialContentState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchContentsAsync.pending, (state: IContentsState) => {
      state.status = RequestStatus.LOADING
    })
    builder.addCase(
      fetchContentsAsync.fulfilled,
      (state: IContentsState, action: any) => {
        state.data = action?.payload
        state.status = RequestStatus.IDLE
      },
    )
  },
})

export const contentsInformation = (state: IGeneralState) =>
  state?.contents?.data || []

export const contentsStatus = (state: IGeneralState) => state.contents.status

export default contentsSlice.reducer
