import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { AxiosResponse } from "axios"
import { Login } from "../../../services/Api"
import {
  IGeneralState,
  ILoginFieldDataType,
  ILoginResponse,
  ILoginState,
} from "../../../models"
import { RequestStatus } from "../../../enums"

const initialLoginState: ILoginState = {
  accessToken: localStorage.getItem("accessToken"),
  refreshToken: localStorage.getItem("refreshToken"),
  status: RequestStatus.EMPTY,
  error: null,
}

export const loginAsync = createAsyncThunk(
  "login",
  async (
    loginData: ILoginFieldDataType,
    { fulfillWithValue, rejectWithValue },
  ) => {
    try {
      const response: AxiosResponse<ILoginResponse> = await Login(loginData)
      return fulfillWithValue(response.data)
    } catch (error: any) {
      return rejectWithValue(error.response.data.errors[0].message)
    }
  },
)

export const loginSlice = createSlice({
  name: "login",
  initialState: initialLoginState,
  reducers: {
    updateLoginError(state) {
      state.error = null
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loginAsync.pending, (state: ILoginState) => {
      state.status = RequestStatus.LOADING
    })
    builder.addCase(loginAsync.fulfilled, (state: ILoginState, action: any) => {
      state.status = RequestStatus.IDLE
      state.accessToken = action.payload.accessToken
      state.refreshToken = action.payload.refreshToken
      localStorage.setItem("refreshToken", action.payload.refreshToken)
      localStorage.setItem("accessToken", action.payload.accessToken)
    })
    builder.addCase(loginAsync.rejected, (state: ILoginState, action: any) => {
      state.status = RequestStatus.ERROR
      state.error = action.payload
    })
  },
})

export const { updateLoginError } = loginSlice.actions

export const accessTokenSelector = (state: IGeneralState) =>
  state.login.accessToken
export const refreshTokenSelector = (state: IGeneralState) =>
  state.login.refreshToken
export const loginStatusSelector = (state: IGeneralState) => state.login.status
export const loginErrorSelector = (state: IGeneralState) => state.login.error

export default loginSlice.reducer
