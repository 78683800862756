import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { AxiosResponse } from "axios"

import { CreateContent } from "../../services/Api"
import {
  IAddContentState,
  ICreateContentBody,
  IFilledDataType,
  IGeneralState,
} from "../../models"
import { RequestStatus } from "../../enums"

const initialAddContentState: IAddContentState = {
  status: RequestStatus.EMPTY,
  error: null,
}

export const createContentAsync = createAsyncThunk(
  "add/content",
  async (
    content: ICreateContentBody,
    { fulfillWithValue, rejectWithValue },
  ) => {
    try {
      const formData = new FormData()
      const { data, mainPhoto} = content
      data.forEach((element: IFilledDataType, index: number) => {
        formData.append(`content[${index}][languageId]`, element?.id.toString())
        formData.append(`content[${index}][title]`, element?.title)
        formData.append(`content[${index}][description]`, element?.description)
        formData.append(`content[${index}][address]`, element?.address)
      })
      formData.append("mainPhoto", mainPhoto)
      const response: AxiosResponse<any> = await CreateContent(formData)
      return fulfillWithValue(response.data)
    } catch (error: any) {
      return rejectWithValue(error.response.data.errors[0].message)
    }
  },
)

export const addContentSlice = createSlice({
  name: "add-content",
  initialState: initialAddContentState,
  reducers: {
    updateContentStatus(state, action: PayloadAction<RequestStatus>) {
      state.status = action.payload
      state.error = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createContentAsync.pending, (state: IAddContentState) => {
      state.status = RequestStatus.LOADING
    })
    builder.addCase(createContentAsync.fulfilled, (state: IAddContentState) => {
      state.status = RequestStatus.IDLE
    })
    builder.addCase(
      createContentAsync.rejected,
      (state: IAddContentState, action: any) => {
        state.status = RequestStatus.ERROR
        state.error = action.payload
      },
    )
  },
})

export const { updateContentStatus } = addContentSlice.actions

export const addContentStatusSelector = (state: IGeneralState) =>
  state.addContent.status
export const addContentErrorSelector = (state: IGeneralState) =>
  state.addContent.error

export default addContentSlice.reducer
