import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RequestStatus } from "../../enums";
import { IDeleteContentMediaState, IGeneralState } from "../../models";
import { AxiosResponse } from "axios";
import { DeleteContentMedia } from "../../services/Api";

const initialDeleteContentMediaState: IDeleteContentMediaState = {
    status: RequestStatus.EMPTY

};

export const fetchDeleteContentMediaAsync = createAsyncThunk(
    "delete/content/media",
    async (id: number, { fulfillWithValue, rejectWithValue }) => {
        try {
            const response: AxiosResponse =
                await DeleteContentMedia(id)
            return fulfillWithValue(response?.data)
        } catch (error: any) {
            return rejectWithValue(error.response.data.errors[0].message)
        }
    },
)

export const deleteContentMediaSlice = createSlice({
    name: "delete-content-media",
    initialState: initialDeleteContentMediaState,
    reducers: {
updateCondentMediaDeletedStatus(state,action:PayloadAction<RequestStatus>){
    state.status = action.payload
}
    },
    extraReducers: (builder) => {
        builder.addCase(fetchDeleteContentMediaAsync.pending, (state: IDeleteContentMediaState) => {
            state.status = RequestStatus.LOADING
          })

        builder.addCase(
            fetchDeleteContentMediaAsync.fulfilled,
            (state: IDeleteContentMediaState) => {
                state.status = RequestStatus.IDLE
            },
        )
        builder.addCase(
            fetchDeleteContentMediaAsync.rejected,
            (state: IDeleteContentMediaState) => {
                state.status = RequestStatus.EMPTY
            },
        )
    },
})

export const {updateCondentMediaDeletedStatus} = deleteContentMediaSlice.actions;

export const deleteContentMediaStatusSelector = (state: IGeneralState) =>
    state.deleteContentMedia.status;

export default deleteContentMediaSlice.reducer