import { createSlice } from "@reduxjs/toolkit";
import {IGeneralState } from "../../models";


export interface ITotalPaginationState {
    page:number;
}


const initialTotalPaginationState: ITotalPaginationState = {
    page:1,
};

export const totalPaginationSlice = createSlice({
    name: "total-pagination",
    initialState: initialTotalPaginationState,
    reducers: {
        updateTotalPagination: {
            reducer(state: ITotalPaginationState, action: any) {
              state.page = action.payload
            },
            prepare(page:number) {
              // The prepare callback allows you to pass additional arguments to the reducer
              return { payload: page }
            },
          },
    },
});

export const {updateTotalPagination} = totalPaginationSlice.actions;
export const totalPaginationSelector = (state: IGeneralState) => state.totalPagination.page;

export default totalPaginationSlice.reducer;