import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { AxiosResponse } from "axios"
import { GetContentById } from "../../services/Api"
import { IContentByIdAsyncData, IGeneralState } from "../../models"
import {
  IContentByIdResponse,
  IContentByIdState,
  IContent,
} from "../../models/get-content-by-id"

const initialContentByIdState: IContentByIdState = {
  data: {} as IContent,
}

export const fetchContentDetailsAsync = createAsyncThunk(
  "get/contents/by/Id",
  async (
    contentsData: IContentByIdAsyncData,
    { fulfillWithValue, rejectWithValue },
  ) => {
    const id = contentsData.id
    const headers = contentsData.headers

    try {
      const response: AxiosResponse<IContentByIdResponse> =
        await GetContentById(id, headers)
      return fulfillWithValue(response?.data)
    } catch (error: any) {
      return rejectWithValue(error.response.data.errors[0].message)
    }
  },
)

export const contentsByIdSlice = createSlice({
  name: "contents/By/Id",
  initialState: initialContentByIdState,
  reducers: {
    resetContentDetailsData(state) {
      state.data = {} as IContent
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchContentDetailsAsync.fulfilled,
      (state: IContentByIdState, action: any) => {
        state.data = action.payload
      },
    )
  },
})

export const { resetContentDetailsData } = contentsByIdSlice.actions

export const contentDetailsSelector = (state: IGeneralState) =>
  state.contentsById.data

export default contentsByIdSlice.reducer
