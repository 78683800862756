import search from "../../assets/icons/search.png"

export function SearchComponent({
  sendSerchValue,
}: {
  sendSerchValue: (event: string) => void
}) {
  return (
    <>
          <img src={search} className="seach_icon" />
          <input
            placeholder="Search"
            onChange={(event: any) => {
              sendSerchValue(event.target.value)
            }}
          />
    </>
  )
}
