import add from "../../../../assets/icons/plus.png"
import { Form, Select, Table } from "antd"
import type { ColumnsType } from "antd/es/table"
import DOMPurify from 'dompurify';
import { saveAs } from "file-saver"

import trash from "../../../../assets/icons/trash.png"
import download from "../../../../assets/icons/download.png"
import edit from "../../../../assets/icons/edit.png"
import { useLocation, useNavigate } from "react-router-dom"
import { useEffect, useState } from "react"
import { DeleteContentItem } from "../../../../services/Api"
import { DeletedModal } from "../../../../modals"
import { contentsInformation, contentsStatus, fetchContentsAsync } from "./slice"
import { IContentData, IContentsAsyncData } from "../../../../models"
import {
  adminCurrentLanguageSelector,
  updateCurrentLanguage,
} from "../../../../global-slice/current-language-slice"

import "react-loading-skeleton/dist/skeleton.css"
import { SkeletonLoading } from "../../../../components"
import { useAppDispatch, useAppSelector } from "../../../../store"
import { RequestStatus } from "../../../../enums"

export function Contents() {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const paginationTake = 10

  const contentsData = useAppSelector(contentsInformation)

  const [deletedModalIsOpen, setDeletedModalIsOpen] = useState<boolean>(false)
  const [contentItem, setContentItem] = useState<number | any>()
  const [selectValue, setSelectValue] = useState("en")

  const photoURL: string = import.meta.env.VITE_CONTENT_PHOTO_URL
  const currentLanguageSelector: string = useAppSelector(
    adminCurrentLanguageSelector,
  )

  const [totalPaginationPage, setTotalPaginationPage] = useState<number>(1);

  const status: string = useAppSelector(contentsStatus);
  const [searchParamsValue, setSearchParamsValue] = useState<string>();
  const searchParams = useLocation();


  const columns: ColumnsType<IContentData> = [
    {
      title: "No.",
      dataIndex: "id",
      key: "id",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Main Photo",
      dataIndex: "photo",
      render: (_, item) => (
        <>
          <img
            alt="contentphoto"
            src={photoURL + item?.mainPhoto?.path}
            className="photo_img"
          />
        </>
      ),
    },
    {
      title: "Title",
      dataIndex: "tittle",
      // render: (_, item) => (
      //   <>
      //     <span>{item?.translations[0]?.title}</span>
      //   </>
        
      // ),
      render:(_,item, index)=>{
        const sanitizedDescription = DOMPurify.sanitize(item?.translations[0]?.title as any);
        const descriptionWithoutTags = sanitizedDescription.replace(/<[^>]*>/g, ''); // Remove HTML tags
        return <span dangerouslySetInnerHTML={{ __html: descriptionWithoutTags }} />;

    }
    },
    {
      title: "Address",
      dataIndex: "address",
      render: (_, item) => (
        <>
          <span>{item?.translations[0]?.address}</span>
        </>
      ),
    },
    {
      title: "QR",
      dataIndex: "qrCode",
      render: (_, item) => (
        <>
          <img src={item?.qrCode} alt="qr icon" className="qr_icon" />
        </>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (_, item) => (
        <>
          <img
            alt="edit icon"
            src={edit}
            className="icon"
            onClick={() => {
              navigate(`/contents/${item?.id}`)
            }}
          />
          <img
            alt="download icon"
            src={download}
            className="icon"
            onClick={() => {
              saveAs(item?.qrCode, "downloaded_image.jpg")
            }}
          />

          <img
            alt="trash icon"
            src={trash}
            className="icon"
            onClick={() => {
              setDeletedModalIsOpen(true)
              setContentItem(item?.id)
            }}
          />
        </>
      ),
    },
  ]

  useEffect(() => {
    navigate({
      pathname: '/contents',
      search: `?search=`,
    });
  }, [])

  // useEffect(() => {
  //   if (searchParams) {
  //     const queryParams = new URLSearchParams(location.search);
  //     const params = queryParams.get('search')?.toString();
  //     console.log(params)
  //     setSearchParamsValue(params)
  //     if (contentsData?.data) {

  //     }
  //     const data: IContentsAsyncData = {
  //       search: searchParamsValue || '',
  //       headers: currentLanguageSelector,
  //       take: paginationTake,
  //       page: totalPaginationPage,
  //     }
  //     dispatch(fetchContentsAsync(data))
  //     setSelectValue(currentLanguageSelector)
  //   }
  // }, [searchParams])

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const params = queryParams.get('search')?.toString();
    const data: IContentsAsyncData = {
      search: params || "",
      headers: currentLanguageSelector,
      take: paginationTake,
      page: totalPaginationPage,
    }
    dispatch(fetchContentsAsync(data))
    setSelectValue(currentLanguageSelector)
  }, [currentLanguageSelector, dispatch, paginationTake, totalPaginationPage, searchParams])

  const languageOnChange = (values: string) => {
    // const data: IContentsAsyncData = {
    //   search: searchParamsValue || '',
    //   headers: values,
    //   take: paginationTake,
    //   page: totalPaginationPage,
    // }
    // dispatch(fetchContentsAsync(data))
    setSelectValue(values)
    dispatch(updateCurrentLanguage(values))
  }

  return (
    <>
      <div>
        {status === RequestStatus.IDLE ? (
          <>
            <div className="content_header">
              <Form
                name="basic"
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 20 }}
                initialValues={{ remember: true }}
                autoComplete="off"
              >
                <Form.Item name="language" initialValue={selectValue}>
                  <Select
                    className="lng_select"
                    style={{ width: "150px", marginRight: "10px" }}
                    onChange={languageOnChange}
                  >
                    <Select.Option value="hy">Հայերեն</Select.Option>
                    <Select.Option value="en">Engish</Select.Option>
                    <Select.Option value="ru">Russian</Select.Option>
                  </Select>
                </Form.Item>
              </Form>
              <button
                className="add_btn"
                onClick={() => {
                  navigate("/contents/add")
                }}
              >
                <img src={add} alt="add icon" />
                Add New
              </button>
            </div>

            <Table
              columns={columns}
              dataSource={contentsData.data || []}
              key={contentsData.data.length}
              rowKey={(record) => record?.id}
              pagination={{
                total: contentsData?.meta?.itemCount || 0,
                defaultPageSize: paginationTake,
                current: totalPaginationPage,
                onChange: (page: number) => {
                  setTotalPaginationPage(Number(page))
                },
              }}
            />
            {deletedModalIsOpen ? (
              <DeletedModal
                text="content"
                setDeletedModalIsOpen={(isOpen: boolean) => {
                  setDeletedModalIsOpen(isOpen)
                  if (isOpen) {
                    DeleteContentItem(contentItem)
                      .then(() => {
                        setDeletedModalIsOpen(false)
                        const data: IContentsAsyncData = {
                          search: searchParamsValue || '',
                          headers: selectValue,
                          take: paginationTake,
                          page: totalPaginationPage,
                        }
                        dispatch(fetchContentsAsync(data))
                      })
                      .catch((error) => { })
                  }
                }}
              />
            ) : null}
          </>
        ) : (
          <>
            <SkeletonLoading />
          </>
        )}
      </div>
    </>
  )
}
